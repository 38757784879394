<template>
  <div class="chart-savings">
    <CommonInlineData
      style="margin-bottom: var(--spacer); min-width: 300px"
      :label="`Économies du mois ${getMonth()}`"
      :data="`${sum()} €`"
    />
    <div style="flex-grow: 1">
      <div style="height: 160px">
        <Doughnut
          :data="{
            labels: dataset.filter((e) => e.value ?? 0 !== 0).map((e) => e.label),
            datasets: [
              {
                backgroundColor: ['#8EB7FF', '#FEC62E', '#FC6944', '#9C5DF4'],
                data: dataset.map((e) => e.value?.value ?? 0)
              }
            ]
          }"
          :options="{
            responsive: true,
            maintainAspectRatio: false,
            cutout: 30,
            plugins: {
              legend: {
                display: false
              },
              tooltip: {
                position: 'nearest',
                callbacks: {
                  label: (e: any) => `${e.formattedValue} €`
                }
              }
            },
            //@ts-ignore
            borderWidth: 0
          }"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import CommonInlineData from '@/components/common/CommonInlineData.vue'
import type { DeviceValue } from '@/service/service_calculations'
import type { ApplicationTreeNode } from '@/service/service_unit'
import { useHypervisionStore } from '@/stores/store_hypervision'
import { Chart as ChartJS, ArcElement, Tooltip, CategoryScale, Legend } from 'chart.js'
import { onMounted, ref } from 'vue'
import { Doughnut } from 'vue-chartjs'

const store = useHypervisionStore()
const dataset = ref<{ label: string; value?: DeviceValue }[]>([])
const props = defineProps<{
  node: ApplicationTreeNode
}>()

function sum() {
  let output = 0
  dataset.value.map((e) => {
    output += e.value?.value ?? 0
  })

  return output.toPrecision(3)
}

function getMonth() {
  const date = new Date()
  let appostroph = false
  const month = date.getMonth()
  if ([4, 7, 9].includes(month)) {
    appostroph = true
  }

  const month_name = date.toLocaleDateString('fr', {
    day: undefined,
    month: 'long',
    year: undefined
  })
  let result = month_name.charAt(0).toUpperCase() + month_name.slice(1)
  return `${appostroph ? "d'" : 'de '}${result}`
}

ChartJS.register(ArcElement, Tooltip, CategoryScale, Legend)
onMounted(() => {
  const devices = store.getNodeDevicesRecursive(props.node)
  console.log(devices)
  dataset.value = devices.map((device) => ({
    label: device.name,
    value: device.values.find((value) => value.metric.includes('€'))
  }))
})
</script>

<style scoped>
.chart-savings {
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>
