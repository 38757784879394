<template>
  <div :class="activeBreakpoint">
    <template v-if="store.getPipelineState === PipelineStates.DONE">
      <CompositeNavbar />
      <RouterView />
    </template>
    <LayoutContainer class="error" v-else-if="store.getPipelineState === PipelineStates.FAIL">
      <TrafficCone color="var(--vc-orange)" />
      <p class="pipeline-state" style="color: var(--vc-orange)">
        {{ store.getPipelineMessage }}
      </p>
    </LayoutContainer>
    <LayoutContainer style="margin-top: 250px" v-else>
      <CommonLoader />
      <p class="pipeline-state">
        Chargement de l'application..<br />
        {{ store.getPipelineMessage }}
      </p>
    </LayoutContainer>
  </div>
</template>

<script setup lang="ts">
import CompositeNavbar from '@/components/composite/CompositeNavbar.vue'
import LayoutContainer from '@/components/layout/LayoutContainer.vue'
import CommonLoader from '@/components/common/CommonLoader.vue'
import { parse_jwt } from '@/service/service_security'
import { useHypervisionStore, PipelineStates } from '@/stores/store_hypervision'
import { onMounted, ref } from 'vue'
import { useBreakpoints } from '@vueuse/core'
import { application_breakpoints } from '@/service/service_breakpoints'
import { TrafficCone } from 'lucide-vue-next'
import { useWeatherStore } from '@/stores/store_weather'

const breakpoints = useBreakpoints(application_breakpoints)
const activeBreakpoint = breakpoints.active()

const store = useHypervisionStore()
const weather = useWeatherStore()

function handleConnection() {
  const jwt = store.getJWT.value
  if (!jwt) {
    return
  }

  const payload = parse_jwt(jwt)
  const expiration_date = new Date(payload.exp * 1000)
  const current_date = new Date()
  const session_duration = expiration_date.getTime() - current_date.getTime()

  console.debug(`logout in ${session_duration}`)
  setTimeout(() => {
    alert('Votre session à exipiré.')
    store.logout()
  }, session_duration)
}

onMounted(() => {
  handleConnection()
  store.pipeline_init()
})
</script>

<style scoped>
.pipeline-state {
  text-align: center;
  color: var(--vc-primary);
  opacity: 0.6;
}

.error {
  margin-top: 400px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
</style>
