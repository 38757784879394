<template>
  <div class="composite-unit-header" v-if="attributes">
    <img
      class="composite-unit-header-image"
      :src="attributes.get('UrlOUImage')"
      alt="Image de l'organisation"
    />
    <CompositeUnitInfos chart :node="node" />
  </div>
</template>

<script setup lang="ts">
import type { ApplicationTreeNode } from '@/service/service_unit'
import { useHypervisionStore } from '@/stores/store_hypervision'
import { onMounted, ref } from 'vue'
import AttributeHelper from '@/service/service_attributes'
import CompositeUnitInfos from './CompositeUnitInfos.vue'

const store = useHypervisionStore()
const attributes = ref<AttributeHelper>()
const props = defineProps<{
  node: ApplicationTreeNode
}>()

onMounted(() => {
  attributes.value = store.getNodeAttributes(props.node)
})
</script>

<style scoped lang="scss">
@import '@/assets/animations.scss';
.composite-unit-header {
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  display: flex;
  gap: var(--spacer-xl);
  flex-wrap: wrap;

  &-informations {
    transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    gap: var(--spacer);

    &-title {
      display: flex;
      justify-content: space-between;
      .text {
        min-width: 250px;
      }
    }

    &-chart {
      flex-grow: 1;
    }
  }

  &-image {
    transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
    animation: fadeInAnimation cubic-bezier(0.19, 1, 0.22, 1) 600ms;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    max-height: 400px;
    min-width: 570px;
    border-radius: var(--br-medium);
    flex-grow: 1;
    object-fit: cover;
    overflow: hidden;
  }
}

.mobile {
  .composite-unit-header-image {
    min-width: 0;
  }
}
</style>
