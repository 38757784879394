<template>
  <LayoutCard>
    <p>Dates :</p>
    <VueDatePicker
      :clearable="false"
      format="dd/MM/y"
      :action-row="{ showPreview: false }"
      :enable-time-picker="false"
      range
      v-model="input_value.base"
      cancel-text="Annuler"
      select-text="Valider"
      style="margin-bottom: 10px"
    />
    <VueDatePicker
      v-if="compare"
      clearable
      format="dd/MM/y"
      :action-row="{ showPreview: false }"
      :enable-time-picker="false"
      range
      v-model="input_value.comp"
      cancel-text="Annuler"
      select-text="Valider"
      style="margin-bottom: 10px"
    />
    <CommonButton
      @click="on_compare_click"
      size="small"
      button
      class="btn-primary chart-device-comparison"
    >
      {{ compare ? 'X' : '+ Comparer avec une date' }}
    </CommonButton>
  </LayoutCard>
</template>

<script setup lang="ts">
import CommonButton from '@/components/common/CommonButton.vue'
import LayoutCard from '@/components/layout/LayoutCard.vue'
import VueDatePicker from '@vuepic/vue-datepicker'
import { computed } from 'vue'

export type ChartDeviceDatePickerData = {
  base?: Date[]
  comp?: Date[]
}

const props = defineProps<{
  compare: boolean
  modelValue: ChartDeviceDatePickerData
}>()

const emits = defineEmits<{
  (e: 'update:modelValue', value: ChartDeviceDatePickerData): void
  (e: 'update:compare', value: boolean): void
}>()

const input_value = computed({
  get: () => {
    return props.modelValue
  },
  set: (v: ChartDeviceDatePickerData): void => {
    emits('update:modelValue', v)
  }
})

function on_compare_click() {
  emits('update:compare', !props.compare)
}
</script>

<style scoped></style>
