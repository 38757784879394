<template>
  <div class="input-common">
    <label v-if="label != ''">{{ label }}</label>
    <input
      :value="modelValue"
      @input="emits('update:modelValue', extract_value($event))"
      :type="type ?? 'text'"
      :placeholder="placeholder"
      :class="{ uptitle: uptitle, gray: gray }"
    />
  </div>
</template>

<script setup lang="ts">
import type { InputTypeHTMLAttribute } from 'vue'

defineProps<{
  label: string
  type?: InputTypeHTMLAttribute
  modelValue?: string
  placeholder?: string
  uptitle?: boolean
  gray?: boolean
}>()

function extract_value(e: Event) {
  return (e.target as HTMLInputElement).value
}

const emits = defineEmits<{
  (e: 'update:modelValue', value: string): void
}>()
</script>

<style scoped lang="scss">
@import '@/assets/mixins.scss';
.input-common {
  @include vlayout(10px);

  input {
    padding: 20px;
    background-color: var(--vc-white);
    width: 100%;
    border-radius: var(--br-small);

    &.gray {
      background-color: var(--vc-gray1);
    }
  }
}
</style>
