<template>
  <LayoutDetail
    :node="node"
    v-if="device && node"
    class="detail-equipment-view"
    :loading="fetching_data"
    :device="device"
  >
    <div class="detail-equipment-view-top">
      <CompositeUnitInfos :node="node" />
    </div>
    <div class="detail-equipment-view-bottom">
      <ChartDevice :device="device" />
    </div>
    <CompositeCardAlerts
      v-if="device.alerts.size > 0"
      :device="device"
      :alerts="device.alerts"
      style="margin-bottom: var(--spacer)"
    />
    <CompositeReporting :alerts="device.alerts" />
  </LayoutDetail>
</template>

<script setup lang="ts">
import LayoutDetail from '@/components/layout/LayoutDetail.vue'
import CompositeUnitInfos from '@/components/composite/CompositeUnitInfos.vue'
import { useHypervisionStore } from '@/stores/store_hypervision'
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import {
  is_node_empty,
  type ApplicationDevice,
  type ApplicationTreeNode
} from '@/service/service_unit'
import type { components } from '@/types/hypervision_schema'
import ChartDevice from '@/components/composite/charts/ChartDevice.vue'
import CompositeReporting from '@/components/composite/CompositeReporting.vue'
import CompositeCardAlerts from '@/components/composite/CompositeCardAlerts.vue'
import AttributeHelper from '@/service/service_attributes'
import { useChartStore } from '@/stores/store_chart'

const store = useHypervisionStore()
const store_chart = useChartStore()
const route = useRoute()
const device = ref<ApplicationDevice>()
const node = ref<ApplicationTreeNode>()
const attributes = ref<AttributeHelper>()
const fetching_data = ref(true)

onMounted(async () => {
  store_chart.init()
  device.value = store.map.dev.get(route.params.id as string)
  if (node.value) {
    attributes.value = store.getNodeAttributes(node.value)
  }

  if (device.value) {
    store_chart.addDevice(device.value)
    node.value = store.map.org.get(device.value.organizationUnitId)
  }

  fetching_data.value = false
})
</script>

<style scoped lang="scss">
@import '@/assets/mixins.scss';
.detail-equipment-view {
  &-top {
    display: flex;
    justify-content: space-between;
    gap: var(--spacer-xl);
    margin-bottom: var(--spacer-xl);
  }

  &-bottom {
    margin-bottom: var(--spacer);
    h2 {
      text-align: center;
      margin-bottom: var(--spacer-1_5);
    }
  }
}

.tablet,
.mobile {
  .detail-equipment-view-top {
    flex-direction: column;
  }
}
</style>

<style lang="scss">
// Choix de ce qui doit être imprimé
@media print {
  .layout-navbar.composite-navbar,
  .common-breadcrumb,
  .detail-equipment-view-top,
  .chart-device-comparison,
  .composite-reporting,
  .chart-section-bottom,
  .layout-card.device-avg,
  .hide-on-print {
    display: none !important;
  }

  .layout-container,
  .layout-detail-content {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .chart-device-chart-inner canvas {
    width: 100% !important;
    height: auto !important;
  }
}
</style>
