<template>
  <BandeauHome />
  <LayoutContainer class="home-view">
    <div class="home-view-global">
      <h2 style="margin-bottom: var(--spacer)">Mes bâtiments</h2>
      <LayoutColumns :columns="3">
        <CompositeCardUnit
          v-if="store.getRoot"
          v-for="node in store.getRoot.childrens"
          :node="node"
        />
        <CompositeCardSavings />
        <CompositeCardInterventions />
      </LayoutColumns>
    </div>
  </LayoutContainer>
</template>

<script setup lang="ts">
import LayoutContainer from '@/components/layout/LayoutContainer.vue'
import BandeauHome from '@/components/common/CommonBandeauHome.vue'
import { useHypervisionStore } from '@/stores/store_hypervision'
import CompositeCardUnit from '@/components/composite/CompositeCardUnit.vue'
import LayoutColumns from '@/components/layout/LayoutColumns.vue'
import CompositeCardSavings from '@/components/composite/CompositeCardSavings.vue'
import CompositeCardInterventions from '@/components/composite/CompositeCardInterventions.vue'

const store = useHypervisionStore()
</script>

<style scoped lang="scss">
@import '@/assets/mixins.scss';
select {
  padding: 20px;
}

.home-view {
  @include vlayout(var(--spacer-xl));
  padding-bottom: 80px;

  &-regroupements {
    h2 {
      margin-bottom: var(--spacer-1_5);
    }
  }
}
</style>
