<template>
  <div class="composite-reporting">
    <div class="composite-reporting-title">
      <h2>Reporting</h2>
      <CommonButton class="btn btn-primary" size="small"> Ajouter un reporting </CommonButton>
    </div>
    <div class="composite-reporting-content">
      <div class="inputs">
        <InputSelect
          id="test"
          novalue="Récurrence"
          label=""
          :options="[{ label: 'Récu', value: 'recu' }]"
          :modelValue="value1"
          uptitle
        />
        <InputSelect
          id="test2"
          novalue="Données"
          label=""
          :options="[{ label: 'Test', value: 'test' }]"
          :modelValue="value2"
          uptitle
        />
        <InputCommon modelValue="" label="" placeholder="Envois - destinataires" uptitle />
      </div>
      <X />
    </div>
  </div>
</template>

<script setup lang="ts">
import { X } from 'lucide-vue-next'
import InputSelect from '@/components/common/input/InputSelect.vue'
import CommonButton from '@/components/common/CommonButton.vue'
import InputCommon from '@/components/common/input/InputCommon.vue'
import { ref } from 'vue'

const value1 = ref<string>()
const value2 = ref<string>()
</script>

<style scoped lang="scss">
@import '@/assets/mixins.scss';
.composite-reporting {
  &-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--spacer-1_5);
  }

  &-content {
    background-color: var(--vc-gray2);
    padding: var(--spacer) var(--spacer-1_5);
    display: flex;
    justify-content: space-between;
    align-items: center;

    .inputs {
      @include hlayout(10px);
      width: 100%;
      margin-right: var(--spacer);

      .input-select,
      .input-common {
        width: 100%;
      }
    }
  }

  .input-select {
    background-color: var(--vc-white);
  }
}

.mobile {
  .composite-reporting-content .inputs {
    @include vlayout(10px);
  }
}
</style>
