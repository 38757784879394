<template>
  <div class="layout-columns" :class="activeBreakpoint">
    <slot />
  </div>
</template>

<script setup lang="ts">
withDefaults(
  defineProps<{
    columns: number
    gap?: string
  }>(),
  {
    gap: 'var(--spacer-xl)'
  }
)
import { useBreakpoints } from '@vueuse/core'
import { application_breakpoints } from '@/service/service_breakpoints'
import { useSlots } from 'vue'

const breakpoints = useBreakpoints(application_breakpoints)
const activeBreakpoint = breakpoints.active()
const slots = useSlots()
</script>

<style scoped lang="scss">
.layout-columns {
  display: grid;
  grid-template-columns: repeat(v-bind(columns), 1fr);
  gap: v-bind(gap);

  &.mobile {
    grid-template-columns: 1fr;
  }

  &.tablet {
    grid-template-columns: 1fr 1fr;
  }
}
</style>
