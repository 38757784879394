<template>
  <Teleport to="body">
    <div v-if="visible" class="layout-modal">
      <LayoutCard class="layout-modal-inner" ref="inner">
        <slot />
      </LayoutCard>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import LayoutCard from './LayoutCard.vue'
import { onClickOutside } from '@vueuse/core'

const visible = ref(false)
const inner = ref()
onClickOutside(inner, close)

function open() {
  visible.value = true
}

function close() {
  visible.value = false
}

defineExpose({ open, close })
</script>

<style scoped>
.layout-modal {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.layout-modal-inner {
  max-width: 600px;
}
</style>
