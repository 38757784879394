<template>
  <LayoutModal ref="modal">
    <h3 class="mb-2">Choisir un équipement :</h3>
    <CommonAccordion
      v-for="node in store.getRoot?.childrens"
      :node="node"
      v-bind:key="node.organizationUnitId"
      @select="select_device"
    />
  </LayoutModal>
</template>

<script setup lang="ts">
import CommonAccordion from '@/components/common/CommonAccordion.vue'
import LayoutModal from '@/components/layout/LayoutModal.vue'
import { useHypervisionStore } from '@/stores/store_hypervision'
import type { components } from '@/types/hypervision_schema'
import { ref } from 'vue'

const modal = ref<InstanceType<typeof LayoutModal>>()
const store = useHypervisionStore()

function select_device(device: components['schemas']['Device']) {
  emits('select', device)
  modal.value?.close()
}

function open() {
  modal.value?.open()
}

defineExpose({ open })

const emits = defineEmits<{
  (e: 'select', value: components['schemas']['Device']): void
}>()
</script>
