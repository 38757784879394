<template>
  <div
    :id="id"
    class="select-replace input-select"
    :class="{
      required,
      disabled
    }"
  >
    <span
      class="select-replace-value"
      :class="{
        'select-rounded': rounded,
        ['select-' + color]: color,
        'select-small': small,
        'select-regular': !small,
        uptitle: uptitle
      }"
      @click="open = !open"
    >
      {{ get_label() ?? novalue ?? 'Sélectionner une option' }}
    </span>
    <div class="select-replace-drop" ref="select_wrapper" :class="{ open }">
      <span
        :data-value="undefined"
        data-label="Sélectionner une option"
        @click="
          () => {
            input_value = undefined
            open = false
          }
        "
      >
        {{ novalue ?? 'Sélectionner une option' }}
      </span>
      <span
        v-bind:key="option.value"
        v-for="option in options"
        :data-value="option.value"
        :data-label="option.label"
        @click="
          () => {
            input_value = option.value
            open = false
          }
        "
      >
        {{ option.label }}
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onClickOutside } from '@vueuse/core'
import { computed, ref } from 'vue'

type SopremaInputSelectOption = {
  label: string
  value: string
}

const props = defineProps<{
  id: string
  label?: string
  options: SopremaInputSelectOption[]
  modelValue?: string
  novalue?: string
  required?: boolean
  disabled?: boolean
  rounded?: boolean
  smallSelect?: boolean
  color?: 'primary' | 'black' | 'outlined'
  small?: boolean
  uptitle?: boolean
}>()

const emits = defineEmits<{
  (e: 'update:modelValue', value?: string): void
}>()

const input_value = computed({
  get: () => {
    if (!props.modelValue) return undefined
    return props.modelValue
  },
  set: (v?: string): void => {
    emits('update:modelValue', v)
  }
})

const select_wrapper = ref()
const open = ref(false)

function get_label() {
  return props.options.find((o) => o.value === input_value.value)?.label
}

onClickOutside(select_wrapper, () => {
  open.value = false
})
</script>

<style scoped lang="scss">
.input-select {
  .select-replace-drop {
    border: 1px solid var(--vc-secondary-0_2);

    span {
      background-color: var(--vc-white);
      color: var(--vc-text);

      &:hover {
        background-color: var(--hover-white);
      }
    }
  }
}

.select-primary,
.select-primary + .select-replace-drop span {
  background-color: var(--vc-primary);
  color: var(--vc-white);

  &:hover {
    background-color: var(--hover-primary);
  }
}

.select-black,
.select-black + .select-replace-drop span {
  background-color: var(--vc-secondary);
  color: var(--vc-white);

  &:hover {
    background-color: var(--hover-secondary);
  }
}

.select-outlined,
.select-outlined + .select-replace-drop span {
  background: var(--vc-white);
  color: var(--vc-secondary-0_5);

  &:hover {
    background-color: var(--hover-white);
  }
}

.select-replace-value.select-outlined::after {
  background-color: var(--vc-secondary-0_5);
}

.select-replace-value.select-black,
.select-replace-value.select-primary {
  &::after {
    background-color: var(--vc-white);
  }

  & + .select-replace-drop {
    border: none;
  }
}

.select-rounded {
  border-radius: 100px;
}

.select-small {
  /* padding right with small (20px) icon width and space between text & icon */
  padding: 15px 65px 15px 40px;
}

.select-regular {
  /* padding right with regular (24px) icon width and space between text & icon */
  padding: 20px 49px 20px 20px;
}

.custom-select {
  position: relative;
}

select {
  display: none !important;
  width: auto !important;
}

.select-replace {
  position: relative;
  border: 0;
  cursor: pointer;
  &:not(.uptitle) {
    width: 100%;
    font-size: 15px;
    font-weight: 700;
    line-height: 18px;
  }

  select {
    appearance: none;
  }

  &-small {
    .select-replace-value {
      padding: 12px 50px 12px 20px;

      &::after {
        right: 20px;
      }
    }

    .select-replace-drop span {
      padding: 10px;
    }
  }
}

.select-replace-value {
  display: block;
  position: relative;
  cursor: pointer;

  &::after {
    position: absolute;
    top: 50%;
    right: 25px;
    width: 20px;
    height: 20px;
    content: '';
    -webkit-mask: url('@/assets/images/icons/chevron-down.svg') no-repeat 50% 50%;
    mask: url('@/assets/images/icons/chevron-down.svg') no-repeat 50% 50%;
    -webkit-mask-size: cover;
    background-color: var(--vc-secondary);
    transform: translateY(-50%);
    transition: all 0.3s ease;
  }

  &.open {
    &::after {
      transform: translateY(-50%) rotate(180deg);
    }
  }
}

.select-replace-drop {
  width: 100%;
  position: absolute;
  transition: all 0.3s ease;
  top: 100%;
  left: 0;
  z-index: 5;
  overflow-x: hidden;
  background-color: var(--vc-black);
  border: 0;
  border-radius: var(--br-small);
  opacity: 0;
  pointer-events: none;

  span {
    pointer-events: inherit;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 20px;
    color: var(--vc-white);
    font-size: 16px;
    line-height: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
}

.open {
  opacity: 1;
  pointer-events: all;
}
</style>
