<template>
  <div>
    <LayoutCard
      v-if="devices.length > 0"
      background="var(--vc-white)"
      bordered
      class="composite-unit-node"
    >
      <h3 style="margin-bottom: var(--spacer-1_5)">
        <Minus color="var(--vc-primary)" /> {{ node.name }}
      </h3>
      <LayoutColumns :columns="3">
        <CompositeCardDevice v-for="device in devices" :device="device" :key="device.deviceId" />
      </LayoutColumns>
    </LayoutCard>
    <CompositeUnitNode
      v-else
      v-for="children in node.childrens"
      :key="children.organizationUnitId"
      :node="children"
    />
  </div>
</template>

<script setup lang="ts">
import {
  type ApplicationDevice,
  type ApplicationTreeNode,
  type UnitNode
} from '@/service/service_unit'
import { useHypervisionStore } from '@/stores/store_hypervision'
import { onMounted, ref } from 'vue'
import CompositeCardDevice from './CompositeCardDevice.vue'
import type { components } from '@/types/hypervision_schema'
import LayoutColumns from '../layout/LayoutColumns.vue'
import LayoutCard from '../layout/LayoutCard.vue'
import { Boxes, Minus } from 'lucide-vue-next'

const store = useHypervisionStore()
const devices = ref<ApplicationDevice[]>([])

const props = defineProps<{
  node: ApplicationTreeNode
}>()

onMounted(() => {
  devices.value = store.getNodeDevices(props.node)
})
</script>

<style scoped>
.composite-unit-node {
  margin-bottom: var(--spacer);
}
</style>
