<template>
  <LayoutCard class="composite-card-alert">
    <div class="composite-card-alert-header">
      <h2>Alertes en cours</h2>
    </div>
    <div class="composite-card-alert-body">
      <div class="alert" v-for="([sensor_id, alert], key) in alerts" :key="sensor_id">
        <div class="tags">
          <CommonTag classes="black">{{
            alert.date.toLocaleDateString('fr', { day: 'numeric', month: 'long', year: 'numeric' })
          }}</CommonTag>
          <CommonTag classes="black">{{ get_sensor(sensor_id)?.name }}</CommonTag>
        </div>
        <h4>{{ alert.title }}</h4>
        <div class="separator" v-if="key !== 3"></div>
      </div>
      <img :src="Background" alt="alert" />
    </div>
  </LayoutCard>
</template>

<script setup lang="ts">
import LayoutCard from '@/components/layout/LayoutCard.vue'
import CommonTag from '../common/CommonTag.vue'
import type { ApplicationAlert } from '@/service/service_unit'
import { useHypervisionStore } from '@/stores/store_hypervision'
import type { ApplicationDevice } from '@/service/service_unit'
import Background from '@/assets/images/tiles-bg.png'

const store = useHypervisionStore()
const props = defineProps<{
  device: ApplicationDevice
  alerts: Map<string, ApplicationAlert>
}>()

function get_sensor(sensor_id: string) {
  const sensors = store.map.sns.get(props.device.deviceId!)
  if (sensors) {
    return sensors.find((sensor) => sensor.sensorId === sensor_id)
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/mixins.scss';
.composite-card-alert {
  @include vlayout(20px);
  position: relative;
  color: var(--vc-white);
  overflow: hidden;
  background-color: var(--vc-orange);
  max-width: 400px;

  &-header {
    h2 {
      text-align: center;
    }
  }

  &-body {
    .separator {
      height: 1px;
      background: var(--vc-white);
      width: 100%;
      margin-bottom: var(--spacer);
      opacity: 20%;
    }

    img {
      position: absolute;
      top: 0;
      object-fit: cover;
      right: 0;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      opacity: 30%;
      mix-blend-mode: soft-light;
    }

    .alert {
      @include vlayout(20px);
      align-items: center;
      .tags {
        @include hlayout(10px);
      }
    }

    h4 {
      text-align: center;
      font-weight: 400;
    }
  }
}
</style>
