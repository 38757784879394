export default class AttributeHelper {
  private attributes: CustomAttribute[]

  constructor(attributes?: string | null) {
    this.attributes = []
    if (attributes !== undefined && attributes !== null && attributes !== '') {
      this.attributes = JSON.parse(attributes)
    }
  }

  public get(code: CustomAttributeCodes) {
    return this.attributes.find((e) => e.code === code)?.value
  }
}
