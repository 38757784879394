<script setup lang="ts">
import { RouterView } from 'vue-router'
import { useBreakpoints } from '@vueuse/core'
import { application_breakpoints } from '@/service/service_breakpoints'

const breakpoints = useBreakpoints(application_breakpoints)
const activeBreakpoint = breakpoints.active()
</script>

<template>
  <RouterView :class="activeBreakpoint" />
</template>

<style scoped></style>
