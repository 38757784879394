<template>
  <LayoutDetail :key="node.organizationUnitId" v-if="node" :node="node">
    <CompositeUnitHeader
      style="margin-bottom: var(--spacer-xl)"
      :node="node"
      :key="node.organizationUnitId"
    />
    <div class="details-view-unit" :class="activeBreakpoint">
      <LayoutColumns style="margin-bottom: var(--spacer)" :columns="3">
        <CompositeCardUnit
          v-for="children in node.childrens.filter((e) => !is_node_empty(e))"
          :key="children.organizationUnitId"
          :node="children"
          style="margin-bottom: var(--spacer)"
        />
      </LayoutColumns>
      <h3 v-if="devices.length > 0" style="margin-bottom: var(--spacer-1_5)">
        <Minus color="var(--vc-primary)" /> Équipements
      </h3>
      <LayoutColumns v-if="devices.length > 0" :columns="3" style="margin-bottom: var(--spacer)">
        <CompositeCardDevice v-for="device in devices" :device="device" :key="device.deviceId" />
      </LayoutColumns>
      <CompositeUnitNode
        v-for="children in node.childrens.filter((e) => is_node_empty(e))"
        :node="children"
        :key="children.organizationUnitId"
      />
    </div>
    <CompositeReporting />
  </LayoutDetail>
</template>

<script setup lang="ts">
import LayoutDetail from '@/components/layout/LayoutDetail.vue'
import CompositeCardDevice from '@/components/composite/CompositeCardDevice.vue'
import { useRoute, useRouter } from 'vue-router'
import { onBeforeMount, onMounted, ref, watch } from 'vue'
import { useHypervisionStore } from '@/stores/store_hypervision'
import CompositeCardUnit from '@/components/composite/CompositeCardUnit.vue'
import AttributeHelper from '@/service/service_attributes'
import LayoutColumns from '@/components/layout/LayoutColumns.vue'
import {
  type ApplicationTreeNode,
  is_node_empty,
  node_return,
  type ApplicationDevice
} from '@/service/service_unit'
import CompositeUnitNode from '@/components/composite/CompositeUnitNode.vue'
import CompositeReporting from '@/components/composite/CompositeReporting.vue'
import { useBreakpoints } from '@vueuse/core'
import { application_breakpoints } from '@/service/service_breakpoints'
import CompositeUnitHeader from '@/components/composite/CompositeUnitHeader.vue'
import { Boxes, Minus } from 'lucide-vue-next'

const store = useHypervisionStore()
const route = useRoute()
const node = ref<ApplicationTreeNode>()
const devices = ref<ApplicationDevice[]>([])
const attributes = ref<AttributeHelper>()
const breakpoints = useBreakpoints(application_breakpoints)
const activeBreakpoint = breakpoints.active()

function unit_init(id: string) {
  node.value = store.getNode(id) ?? undefined
  if (node.value) {
    attributes.value = store.getNodeAttributes(node.value)
    devices.value = store.getNodeDevices(node.value)
  }
}

watch(
  () => route.params.id,
  (id) => unit_init(id as string)
)

onBeforeMount(() => {
  unit_init(route.params.id as string)
})
</script>

<style scoped lang="scss">
@import '@/assets/mixins.scss';
.details-view {
  &-top {
    display: flex;
    gap: var(--spacer-xl);
    margin-bottom: var(--spacer-xl);
    max-height: 400px;

    &-right {
      width: 100%;
    }
  }

  &-bottom {
    @include hlayout(var(--spacer-xl));
    justify-content: space-between;
    margin-bottom: var(--spacer-xl);

    &-left {
      @include hlayout(var(--spacer-1_5));
      flex-wrap: wrap;
      flex: auto;

      .layout-card {
        flex: 0 0 360px;
      }
    }

    &-right {
      flex: 1 1 350px;
    }
  }

  &-units {
    display: flex;
    gap: var(--spacer);
    margin-bottom: var(--spacer-1_5);
    flex-wrap: wrap;
  }
}

.tablet,
.mobile {
  .details-view {
    &-top {
      flex-direction: column;
    }
  }
}

.details-view-unit {
  margin-bottom: var(--spacer);
}

.img-container {
  border-radius: var(--br-medium);
  overflow: hidden;
  min-width: 570px;
  max-width: 570px;
  max-height: 400px;
}

.img-container > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
