import { ref, type Ref } from 'vue'

function deleteCookie(name: string) {
  if (getCookie(name) !== null) {
    document.cookie = `${name}=;path=/;expires=Thu,01 Jan 1970 00:00:01 GMT;SameSite=strict;Secure`
  }
}

// Helper function to set a cookie
function setCookie(name: string, value: string, expiration_date?: Date): void {
  let expires = ''
  if (expiration_date) {
    expires = `; expires=${expiration_date.toUTCString()}`
  }
  document.cookie = `${name}=${value}${expires};path=/;SameSite=strict;Secure`
}

// Helper function to get a cookie value
function getCookie(name: string): string | null {
  const cookies = document.cookie.split(';')
  for (const cookie of cookies) {
    const [cookieName, cookieValue] = cookie.split('=').map((c) => c.trim())
    if (cookieName === name) {
      return decodeURIComponent(cookieValue)
    }
  }
  return null
}

// Vue composable for managing cookies
export function useCookies(): {
  getCookieValue: (name: string) => Ref<string | null>
  setCookieValue: (name: string, value: string, expiration_date?: Date) => void
  deleteCookieValue: (name: string) => void
} {
  // Reactive reference for cookie value
  const cookieValue: Ref<string | null> = ref(null)

  // Function to get cookie value
  const getCookieValue = (name: string): Ref<string | null> => {
    cookieValue.value = getCookie(name)
    return cookieValue
  }

  // Function to set cookie
  const setCookieValue = (name: string, value: string, expiration_date?: Date): void => {
    setCookie(name, value, expiration_date)
  }

  const deleteCookieValue = (name: string): void => deleteCookie(name)

  return {
    getCookieValue,
    setCookieValue,
    deleteCookieValue
  }
}
