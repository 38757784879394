<template>
  <div class="layout-container">
    <slot />
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/mixins.scss';
.layout-container {
  @include container;
}
</style>
