<template>
  <LayoutDetail class="view-account">
    <h1>Vos données personnelles</h1>
    <div class="user-infos">
      <div class="user-infos-item">
        <h3>Nom</h3>
        <p>{{ store.getUser?.surname }}</p>
      </div>
      <div class="user-infos-item">
        <h3>Prénom</h3>
        <p>{{ store.getUser?.firstname }}</p>
      </div>
      <div class="user-infos-item">
        <h3>Email</h3>
        <p>{{ store.getUser?.email }}</p>
      </div>
      <div class="user-infos-item">
        <h3>Login</h3>
        <p>{{ store.getUser?.login }}</p>
      </div>
      <CommonButton @click="openPopup" class="btn-primary"> Se déconnecter </CommonButton>
      <div class="logout-popup">
        <h3>Êtes-vous sûr de vouloir vous déconnecter ?</h3>
        <div class="logout-actions">
          <CommonButton @click="store.logout" class="btn-primary"> Se déconnecter </CommonButton>
          <CommonButton @click="closePopup" class="btn-secondary"> Annuler </CommonButton>
        </div>
      </div>
      <div class="body-backdrop"></div>
    </div>
  </LayoutDetail>
</template>

<script setup lang="ts">
import CommonButton from '@/components/common/CommonButton.vue'
import LayoutDetail from '@/components/layout/LayoutDetail.vue'
import { useHypervisionStore } from '@/stores/store_hypervision'
const store = useHypervisionStore()

function openPopup() {
  const popup = document.querySelector('.logout-popup') as HTMLElement
  const backdrop = document.querySelector('.body-backdrop') as HTMLElement
  popup.style.display = 'flex'
  backdrop.style.display = 'block'
}

function closePopup() {
  const popup = document.querySelector('.logout-popup') as HTMLElement
  const backdrop = document.querySelector('.body-backdrop') as HTMLElement
  popup.style.display = 'none'
  backdrop.style.display = 'none'
}
</script>

<style scoped lang="scss">
@import '@/assets/mixins.scss';

.user-infos {
  @include vlayout(var(--spacer));

  .logout {
    &-popup {
      display: none;
      flex-direction: column;
      justify-content: space-between;
      position: absolute;
      background: var(--vc-white);
      height: 200px;
      width: 500px;
      padding: var(--spacer);
      top: calc(50% - 100px);
      left: calc(50% - 250px);
      border-radius: var(--br-medium);
      z-index: 1;
    }

    &-actions {
      display: flex;
      justify-content: space-between;
    }
  }

  .body-backdrop {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #000;
    opacity: 0.5;
    width: 100%;
    height: 100%;
  }
}

.mobile {
  .logout-popup {
    width: calc(100% - 2 * var(--spacer));
    height: 250px;
    left: var(--spacer);

    .logout-actions {
      @include vlayout(10px);

      .btn {
        min-width: 100%;
      }
    }
  }
}
</style>
