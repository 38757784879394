<template>
  <LayoutColumns :columns="2" style="margin-bottom: var(--spacer); margin-top: var(--spacer)">
    <template v-for="serie in series">
      <LayoutCard class="device-avg">
        <div class="device-avg-header">
          <p>Moyenne</p>
          <span class="uptitle">{{ serie.name }}</span>
        </div>
        <h3>{{ d2(avg(serie.data as any)) }} {{ store.map.uni.get(serie.unit)?.symbol }}</h3>
      </LayoutCard>
      <LayoutCard class="device-avg" v-if="!skip_total.includes(serie.code)">
        <div class="device-avg-header">
          <p>Total</p>
          <span class="uptitle">{{ serie.name }}</span>
        </div>
        <h3>{{ d2(sum(serie.data as any)) }} {{ store.map.uni.get(serie.unit)?.symbol }}</h3>
      </LayoutCard>
      <div v-else></div>
    </template>
  </LayoutColumns>
</template>

<script setup lang="ts">
import { d2 } from '@/service/service_calculations'
import LayoutCard from '../layout/LayoutCard.vue'
import LayoutColumns from '../layout/LayoutColumns.vue'
import { useHypervisionStore } from '@/stores/store_hypervision'
import type { Serie } from '@/stores/store_chart'

defineProps<{
  series: Serie[]
}>()

const store = useHypervisionStore()
const skip_total = ['HR', 'POS', 'HC', 'DH', 'OCC', 'DC', 'POURC']

function sum(measures: { y: number }[]) {
  let sum = 0
  for (const measure of measures) {
    sum += measure.y ?? 0
  }

  return sum
}

function avg(measures: { y: number }[]) {
  if (measures.length == 0) return 0
  const s = sum(measures)
  return s / measures.length
}
</script>

<style scoped>
.device-avg-header > p {
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-weight: 600;
}
</style>
