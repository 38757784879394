import { useCookies } from '@/components/composable/composable_cookie'
import type { components, paths } from '@/types/gateway_schema'
import createClient from 'openapi-fetch'
import { defineStore } from 'pinia'

type GatewayStoreState = {
  user_charts: components['schemas']['ChartDTO'][]
}

const client = createClient<paths>({
  baseUrl: '/gateway'
})

export const useGatewayStore = defineStore('gateway', {
  state: (): GatewayStoreState => ({
    user_charts: []
  }),
  getters: {
    getJWT: (_) => {
      const { getCookieValue } = useCookies()
      const cookie = getCookieValue('hv_jwt')
      return cookie
    }
  },
  actions: {
    async fetchUserCharts() {
      return client
        .GET('/api/charts', { headers: { 'X-AUTH-TOKEN': this.getJWT.value } })
        .then((response) => {
          if (response.data) {
            this.user_charts = response.data
          }
        })
    },
    async postChart(chart: components['schemas']['ChartDTO']) {
      return client
        .POST('/api/charts', { body: chart, headers: { 'X-AUTH-TOKEN': this.getJWT.value } })
        .then((response) => {
          if (response.data) {
            this.user_charts.push(response.data)
            alert(`Le graphique ${response.data.id?.toString().padStart(5, '0')} à été enregistré.`)
          }
        })
    },
    async patchChart(id: number, chart: components['schemas']['ChartDTO']) {
      return client
        .PATCH('/api/charts/{id}', {
          body: chart,
          headers: { 'X-AUTH-TOKEN': this.getJWT.value },
          params: {
            path: {
              id: id.toString()
            }
          }
        })
        .then((response) => {
          if (response.data) {
            const key = this.user_charts.findIndex((e) => e.id === id)
            this.user_charts[key] = response.data
            alert(`Le graphique ${response.data.id?.toString().padStart(5, '0')} à été sauvegardé.`)
          }
        })
    },
    async deleteChart(id: number) {
      return client
        .DELETE('/api/charts/{id}', {
          headers: { 'X-AUTH-TOKEN': this.getJWT.value },
          params: { path: { id: id.toString() } }
        })
        .then(() => {
          const key = this.user_charts.findIndex((e) => e.id === id)
          this.user_charts.splice(key, 1)
        })
    }
  }
})
