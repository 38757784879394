<template>
  <div class="common-breadcrumb">
    <template v-for="(entry, key) in path" :key="entry">
      <RouterLink
        :to="entry.link"
        class="link"
        :class="{ current: entry.current, inactive: !entry.clickable }"
      >
        {{ entry.title }}
      </RouterLink>
      <span class="separator" v-if="key + 1 !== path.length || device">/</span>
    </template>
    <a v-if="device" class="link current">
      {{ device.name }}
    </a>
  </div>
</template>

<script setup lang="ts">
import {
  is_node_empty,
  type ApplicationDevice,
  type ApplicationTreeNode
} from '@/service/service_unit'
import { useHypervisionStore } from '@/stores/store_hypervision'

const props = defineProps<{
  node: ApplicationTreeNode
  device?: ApplicationDevice
}>()

type BreadcrumbPath = { title: string; link: string; clickable: boolean; current: boolean }

const store = useHypervisionStore()
const path = make_path()

function make_path(): BreadcrumbPath[] {
  const make_self = (node: ApplicationTreeNode, path: BreadcrumbPath[] = []) => {
    if (node.code != 'ROOT_SOPREMA') {
      path.unshift({
        title: node.name,
        clickable: !is_node_empty(node),
        current: node.organizationUnitId === props.node.organizationUnitId && !props.device,
        link: `/dashboard/unit/${node.organizationUnitId!}`
      })
    } else {
      path.unshift({
        title: 'Accueil',
        clickable: true,
        link: '/dashboard',
        current: false
      })
    }

    const parent = store.getNodeParent(node)
    if (parent) {
      make_self(parent, path)
    }
  }

  const output: BreadcrumbPath[] = []
  make_self(props.node, output)
  return output
}
</script>

<style scoped>
.common-breadcrumb {
  padding-top: 18px;
  padding-bottom: 18px;
}

.inactive {
  text-decoration: none !important;
  cursor: default;
  pointer-events: none;
}

.current {
  text-decoration: none !important;
  pointer-events: none;
}

.link {
  text-decoration: underline;
}

.separator {
  padding-left: 5px;
  padding-right: 5px;
  pointer-events: none;
}
</style>
