<template>
  <div class="composite-site-parameters">
    <CompositeCardUnit class="composite-site-parameters-unit" :node="node" />
    <div class="composite-site-parameters-form">
      <LayoutColumns :columns="2" gap="15px">
        <CompositeSiteParameterInput
          v-for="parameter in store.raw.dpa"
          class="composite-site-parameters-input"
          :label="parameter.name"
          :unit="store.map.uni.get(parameter.unitId)?.symbol"
          v-model="parameter.valeur"
        />
      </LayoutColumns>
      <div class="composite-site-parameters-footer">
        <CommonButton @click="store.updateDeviceParameters" class="btn btn-primary" button>
          Mettre à jour
          <ArrowRight :size="18" />
        </CommonButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ArrowRight } from 'lucide-vue-next'
import CommonButton from '../common/CommonButton.vue'
import CompositeCardUnit from './CompositeCardUnit.vue'
import { node_path, type ApplicationTreeNode } from '@/service/service_unit'
import CompositeSiteParameterInput from './CompositeSiteParameterInput.vue'
import { reactive, ref } from 'vue'
import { useHypervisionStore } from '@/stores/store_hypervision'
import LayoutColumns from '../layout/LayoutColumns.vue'

const props = defineProps<{
  node: ApplicationTreeNode
}>()

const store = useHypervisionStore()

function update_datas() {}
</script>

<style scoped lang="scss">
@import '@/assets/mixins.scss';
.composite-site-parameters {
  display: flex;
  gap: var(--spacer);
  flex-wrap: wrap;

  &-unit {
    flex-grow: 1;
    min-width: 400px;
  }

  &-form {
    flex-grow: 1;
  }

  &-footer {
    display: flex;
    justify-content: end;
  }
}
</style>
