import { createRouter, createWebHistory, useRoute } from 'vue-router'
import HomeView from '../views/dashboard/ViewDashboardHome.vue'
import AlertsView from '../views/dashboard/ViewDashboardAlerts.vue'
import MyChartsView from '../views/dashboard/ViewDashboardCharts.vue'
import MyDatasView from '../views/dashboard/ViewDashboardData.vue'
import UnitView from '@/views/dashboard/ViewDashboardUnit.vue'
import DeviceView from '@/views/dashboard/ViewDashboardDevice.vue'
import DashboardView from '@/views/ViewDashboard.vue'
import AuthenticationView from '@/views/ViewAuthentication.vue'
import { useHypervisionStore } from '@/stores/store_hypervision'
import AccountView from '@/views/ViewAccount.vue'
import ChartBuilderView from '@/views/dashboard/ViewDashboardChartBuilder.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior: () => {
    return { top: 0, behavior: 'smooth' }
  },
  routes: [
    {
      path: '/',
      name: 'connect',
      component: AuthenticationView,
      beforeEnter: async () => {
        const store = useHypervisionStore()
        if (store.authenticated === true) {
          return '/dashboard'
        }
      }
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: DashboardView,
      beforeEnter: async () => {
        const store = useHypervisionStore()
        if (store.authenticated === false) {
          return '/'
        }
      },
      children: [
        {
          path: '',
          name: 'home',
          component: HomeView
        },
        {
          path: 'alerts',
          name: 'alerts',
          component: AlertsView
        },
        {
          path: 'charts',
          name: 'charts',
          component: MyChartsView
        },
        {
          path: 'datas',
          name: 'datas',
          component: MyDatasView
        },
        {
          path: 'unit/:id',
          name: 'unit',
          component: UnitView
        },
        {
          path: 'device/:id',
          name: 'device',
          component: DeviceView
        },
        {
          path: 'account',
          name: 'account',
          component: AccountView
        },
        {
          path: 'chart_builder',
          name: 'chart_builder',
          component: ChartBuilderView
        }
      ]
    }
  ]
})

export default router
