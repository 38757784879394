<template>
  <LayoutNavbar class="composite-navbar">
    <RouterLink to="/dashboard">
      <img :src="getLogo()" />
    </RouterLink>
    <CompositeMenu />
    <div class="composite-navbar-right">
      <RouterLink to="/dashboard/account" class="composite-navbar-user">
        <User class="icon-user" />
        <p>Bonjour, {{ store.getUser?.firstname }}</p>
      </RouterLink>
      <Settings class="icon-settings" />
    </div>
    <CompositeMenuMobile />
  </LayoutNavbar>
</template>

<script setup lang="ts">
import LayoutNavbar from '../layout/LayoutNavbar.vue'
import CompositeMenu from './CompositeMenu.vue'
import CompositeMenuMobile from './CompositeMenuMobile.vue'
import { User } from 'lucide-vue-next'
import { Settings } from 'lucide-vue-next'
import { useHypervisionStore } from '@/stores/store_hypervision'

const store = useHypervisionStore()
function getLogo() {
  const root = store.getRoot
  if (root) {
    const attributes = store.getNodeAttributes(root)
    return attributes.get('UrlOUImage')
  }
  return '@/assets/logo.svg'
}
</script>

<style scoped lang="scss">
@import '@/assets/mixins.scss';
.tablet,
.mobile {
  .composite-menu,
  .composite-navbar-right {
    display: none;
  }
}

.composite-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  img {
    max-height: 80px;
  }

  &-user {
    @include hlayout(5px);
    align-items: center;
    margin: 0 var(--spacer) 0 var(--spacer-1_5);

    p {
      font-size: 14px;
      line-height: 16px;
    }
  }

  &-right {
    display: flex;
    align-items: center;
  }

  .icon-user,
  .icon-settings {
    width: 16px;
    height: 16px;
  }
}
</style>
