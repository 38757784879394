import { useHypervisionStore, type HypervisionStoreRawData } from '@/stores/store_hypervision'
import type { components } from '@/types/hypervision_schema'
import type { DeviceValue } from './service_calculations'
import AttributeHelper from './service_attributes'

/**
 * Get the path to the organization unit,
 * skips the steps if there is only
 * 1 child unit
 */
export function node_path(node: ApplicationTreeNode) {
  if (node.childrens.length === 1) {
    return `/dashboard/unit/${node.childrens[0].organizationUnitId}`
  }

  return `/dashboard/unit/${node.organizationUnitId}`
}

/**
 * Get the return path of an unit
 * if there is only 1 unit in the level above
 * skips to the next one
 */
export function node_return(node: ApplicationTreeNode) {
  const store = useHypervisionStore()

  if (node.parentOrganizationUnitId === store.getRoot?.organizationUnitId) {
    return `/dashboard`
  }

  const parent_node = store.getNode(node.parentOrganizationUnitId!)
  if (parent_node) {
    if (parent_node.childrens.length === 1) {
      if (parent_node.parentOrganizationUnitId === store.getRoot?.organizationUnitId) {
        return `/dashboard`
      }
      return `/dashboard/unit/${parent_node.parentOrganizationUnitId}`
    }
  }

  return `/dashboard/unit/${node.parentOrganizationUnitId}`
}

export type UnitTree = {
  ref: Map<string, UnitNode>
  root: UnitNode
}

export type UnitNode = {
  id: string
  unit: components['schemas']['OrganizationUnit']
  parent: UnitNode | null
  children: UnitNode[]
}

export function is_node_empty(node: ApplicationTreeNode) {
  if (node.childrens.length === 1) {
    return is_node_empty(node.childrens[0])
  }

  const store = useHypervisionStore()
  return store.getNodeParent(node)?.childrens.length == 1 || node.childrens.length == 0
}

export type ApplicationAlert = {
  title: string
  date: Date
}

export type ApplicationDevice = components['schemas']['Device'] & {
  values: DeviceValue[]
  year: DeviceValue[]
  alerts: Map<string, ApplicationAlert>
}

export type ApplicationTreeNode = components['schemas']['OrganizationUnit'] & {
  childrens: ApplicationTreeNode[]
}

export function create_application_map(
  raw: HypervisionStoreRawData
): Map<string, ApplicationTreeNode> {
  const hashmap = new Map<string, ApplicationTreeNode>()
  for (let i = 0; i < raw.org.length; i++) {
    const unit = raw.org[i]

    const node: ApplicationTreeNode = {
      ...unit,
      childrens: []
    }

    hashmap.set(unit.organizationUnitId!, node)
  }

  return hashmap
}

export function create_application_tree(
  hashmap: Map<string, ApplicationTreeNode>
): ApplicationTreeNode {
  let root: ApplicationTreeNode | null = null
  hashmap.forEach((node) => {
    if (node.code !== 'ROOT_SOPREMA') {
      const parent_node = hashmap.get(node.parentOrganizationUnitId!)
      parent_node?.childrens.push(node)
    } else {
      root = node
    }
  })

  if (root === null) {
    throw 'Could not find root application node'
  }

  return root
}

export function search(node: ApplicationTreeNode, id: string): ApplicationTreeNode | null {
  if (node.organizationUnitId === id) return node
  for (const children of node.childrens) {
    const result = search(children, id)
    if (result) return result
  }

  return null
}
