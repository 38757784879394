export function getMonthInterval(date: Date): { first: string; last: string } {
  const year = date.getFullYear()
  const month = date.getMonth()
  const firstDayOfMonth = new Date(year, month, 1)
  const lastDayOfMonth = new Date(year, month + 1, 0)
  const firstDayString = firstDayOfMonth.toISOString().substring(0, 10)
  const lastDayString = lastDayOfMonth.toISOString().substring(0, 10)

  return {
    first: firstDayString,
    last: lastDayString
  }
}

export function getDateStringYMD(date: Date) {
  if (!date) return ''
  return `${date.getFullYear()}-${f0(date.getMonth() + 1)}-${f0(date.getDate())}`
}

function f0(n: number) {
  if (n < 10) {
    return `0${n}`
  }

  return n.toString()
}
