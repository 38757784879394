import type { components } from '@/types/hypervision_schema'
import { defineStore } from 'pinia'

type WeatherForecast = {
  date: string
  icon: string
}

type WeatherStoreState = {
  forecasts: Map<string, WeatherForecast[]>
}

export const useWeatherStore = defineStore('weather', {
  state: (): WeatherStoreState => ({
    forecasts: new Map()
  }),
  actions: {
    fetchForecast(unit: components['schemas']['OrganizationUnit']) {
      let forecast = this.forecasts.get(unit.organizationUnitId!)
      if (!forecast) {
        this.forecasts.set(unit.organizationUnitId!, [])
        forecast = this.forecasts.get(unit.organizationUnitId!)
      }

      if (forecast!.length > 0 || !unit.localization) {
        return
      }

      const request_url = new URL('https://api.weatherapi.com/v1/forecast.json')
      request_url.searchParams.append('key', 'af31b2047ced4023946134607242106')
      request_url.searchParams.append('q', unit.localization!)
      request_url.searchParams.append('days', '3')
      request_url.searchParams.append('aqi', 'no')
      request_url.searchParams.append('alerts', 'no')

      fetch(request_url.toString()).then(async (response) => {
        const data = await response.json()
        const values = []

        for (const day of data.forecast.forecastday) {
          values.push({
            date: day.date,
            icon: day.day.condition.icon
          })
        }

        this.forecasts.set(unit.organizationUnitId!, values)
      })
    }
  }
})
