<template>
  <div class="layout-navbar">
    <slot />
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/mixins.scss';
.layout-navbar {
  @include container;
  padding-top: 16px;
  padding-bottom: 16px;
  height: 110px;
}
</style>
