<template>
  <LayoutDetail class="view-dashboard-data">
    <div class="view-dashboard-data-header">
      <h1>Mes données</h1>
    </div>
    <CompositeSiteParameters
      v-if="store.getRoot?.childrens[0]"
      :node="store.getRoot.childrens[0]!"
    />
  </LayoutDetail>
</template>

<script setup lang="ts">
import LayoutDetail from '@/components/layout/LayoutDetail.vue'
import { useHypervisionStore } from '@/stores/store_hypervision'
import CompositeSiteParameters from '@/components/composite/CompositeSiteParameters.vue'

const store = useHypervisionStore()
</script>

<style scoped>
.view-dashboard-data-header {
  margin-bottom: var(--spacer);
}
</style>
