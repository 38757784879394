<template>
  <div class="chart-section-bottom">
    <CommonButton :disabled="store_chart.fetching" class="btn btn-primary" @click="emits('onSave')">
      Sauvegarder la configuration du graphique
      <Save v-if="!store_chart.fetching" stroke-width="1" :size="18" />
      <Loader v-else :size="18" />
    </CommonButton>

    <div class="chart-section-bottom-actions">
      <CommonButton @click="print" class="btn btn-primary">
        Imprimer
        <Printer stroke-width="1" :size="18" />
      </CommonButton>

      <CommonButton class="btn btn-primary" @click="emits('onExport')">
        Exporter
        <Download stroke-width="1" :size="18" />
      </CommonButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import CommonButton from '@/components/common/CommonButton.vue'
import { useChartStore } from '@/stores/store_chart'
import { Loader, Save } from 'lucide-vue-next'
import { Printer } from 'lucide-vue-next'
import { Download } from 'lucide-vue-next'

const store_chart = useChartStore()

const emits = defineEmits<{
  (e: 'onExport'): void
  (e: 'onSave'): void
}>()

function print() {
  window.print()
}
</script>

<style scoped lang="scss">
@import '@/assets/mixins.scss';
.chart-section {
  &-bottom {
    display: flex;
    justify-content: space-between;

    div {
      @include hlayout(var(--spacer));
    }
  }
}

.tablet,
.mobile {
  .chart-section-bottom {
    flex-direction: column;
    gap: var(--spacer);

    &-actions {
      flex-direction: column;
    }
  }
}
</style>
