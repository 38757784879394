<template>
  <div class="composite-menu-mobile">
    <div class="composite-menu-mobile-toggle" @click="toggleMenu">
      <Menu />
    </div>
    <div class="mobile-menu" ref="menuMobile">
      <X @click="toggleMenu" class="icon-close" />
      <CompositeMenu />
    </div>
  </div>
</template>

<script setup lang="ts">
import CompositeMenu from './CompositeMenu.vue'
import { Menu } from 'lucide-vue-next'
import { X } from 'lucide-vue-next'
import { ref } from 'vue'

// Référence à l'élément .composite-menu-mobile
const menuMobile = ref<HTMLElement | null>(null)

// Fonction pour afficher le menu
const toggleMenu = () => {
  if (menuMobile.value) {
    menuMobile.value.classList.toggle('open')
  } else {
    console.error('menuMobile is not defined')
  }
}
</script>

<style scoped lang="scss">
.desktop,
.laptop {
  .composite-menu-mobile {
    display: none;
  }
}

.composite-menu-mobile {
  &-toggle {
    background-color: var(--vc-primary);
    border-radius: 100px;
    color: var(--vc-white);
    height: 44px;
    width: 44px;
    padding: 10px;
  }
}

.mobile-menu {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  background-color: var(--vc-white);
  padding: 80px 20px;
  border-bottom: 5px solid var(--vc-primary);

  .icon-close {
    color: var(--vc-primary);
    position: absolute;
    right: 20px;
    top: 20px;
  }

  &.open {
    display: block;
  }
}
</style>
