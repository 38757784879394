<template>
  <div v-if="attributes" class="composite-unit-informations">
    <div class="composite-unit-informations-title">
      <div class="text">
        <p class="uptitle country">{{ node.localization }}</p>
        <h1 class="site-name">{{ node.name }}</h1>
        <p>{{ attributes.get('AdressePostale') }}</p>
      </div>
      <CommonWeatherTable :node="node" />
    </div>
    <ChartSavings v-if="chart" class="composite-unit-informations-chart" :node="node" />
  </div>
</template>

<script setup lang="ts">
import CommonWeatherTable from '@/components/common/CommonWeatherTable.vue'
import AttributeHelper from '@/service/service_attributes'
import type { ApplicationTreeNode } from '@/service/service_unit'
import { useHypervisionStore } from '@/stores/store_hypervision'
import { onMounted, ref } from 'vue'
import ChartSavings from './charts/ChartSavings.vue'

const store = useHypervisionStore()
const attributes = ref<AttributeHelper>()

const props = defineProps<{
  node: ApplicationTreeNode
  chart?: boolean
}>()

onMounted(() => {
  attributes.value = store.getNodeAttributes(props.node)
})
</script>

<style scoped lang="scss">
@import '@/assets/mixins.scss';

.composite-unit-informations {
  transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  gap: var(--spacer);

  &-title {
    display: flex;
    gap: var(--spacer);
    justify-content: space-between;
    .text {
      width: 280px;
    }
  }

  &-chart {
    flex-grow: 1;
    max-width: fit-content;
  }
}

.mobile {
  .composite-unit-informations-title {
    @include vlayout(10px);
  }
}
</style>
