/**
 * @todo File refactoring
 * Clean up every methods and calculations
 * to be more clear
 */

import { useHypervisionStore } from '@/stores/store_hypervision'
import type { components } from '@/types/hypervision_schema'
import type { ApplicationDevice } from './service_unit'

export type DeviceValue = {
  code: string
  title: string
  value: number
  metric: string
  unit?: string
}

export type DeviceValues = {
  title: string
  code: string
  values: { date: string; value: number }[]
}

interface Calculation {
  (
    variable: components['schemas']['Variable'],
    measures: components['schemas']['Measure'][],
    code: string
  ): DeviceValue[]
}

async function execute_calculation(
  variables: components['schemas']['Variable'][],
  code: string,
  start: string,
  end: string,
  calculation: Calculation
) {
  const store = useHypervisionStore()
  for (const variable of variables) {
    if (!variable.variableId) continue
    if (variable.code === code) {
      const measures = await store.fetchMeasuresVariable(variable.variableId, start, end)
      if (variable.deviceId === '535160d1-0bd6-4ba0-9257-9ad978e4227a') {
      }
      if (!measures || !measures.data) continue
      return calculation(variable, measures.data, code)
    }
  }
  return []
}

async function get_month_data(
  variables: components['schemas']['Variable'][],
  code: string,
  start: string,
  end: string
): Promise<DeviceValues | undefined> {
  const store = useHypervisionStore()
  for (const variable of variables) {
    if (!variable.variableId) continue
    if (variable.code === code) {
      const measures = await store.fetchMeasuresVariable(variable.variableId, start, end)
      if (!measures || !measures.data) continue
      return {
        title: variable.name,
        code: variable.code,
        values: measures.data
          .map((e) => ({ date: e.measureDate, value: e.decimalValue ?? 0 }))
          .sort((a, b) => {
            const time_a = new Date(a.date)
            const time_b = new Date(b.date)
            return time_a.getTime() - time_b.getTime()
          })
      }
    }
  }
}

function sum(
  variable: components['schemas']['Variable'],
  measures: components['schemas']['Measure'][],
  code: string
): DeviceValue[] {
  let sum = 0
  for (let i = 0; i < measures.length; i++) {
    const measure = measures[i]
    if (variable.deviceId === '535160d1-0bd6-4ba0-9257-9ad978e4227a') {
    }
    sum += measure.decimalValue ?? 0
  }

  return [
    { title: variable.name, value: sum, metric: variable.symbol ?? '', code, unit: variable.unitId }
  ]
}

function avg(
  variable: components['schemas']['Variable'],
  measures: components['schemas']['Measure'][],
  code: string
): DeviceValue[] {
  return [
    {
      title: variable.name,
      value: measures.length === 0 ? 0 : sum(variable, measures, code)[0].value / measures.length,
      metric: '',
      code,
      unit: variable.unitId
    }
  ]
}

/**
 * Device values retrival for device pages
 * will include all variables and request data
 * for months to be added in the graph view
 */
export async function get_device_values_month(
  device: components['schemas']['Device'],
  variables: components['schemas']['Variable'][],
  start_date: string,
  end_date: string
): Promise<DeviceValues[]> {
  const store = useHypervisionStore()
  const month = store.getMonth

  switch (device.type) {
    case 'KIPO':
      const eco = await get_month_data(variables, 'CPLX_D_EV_ECO', start_date, end_date)
      const eur = await get_month_data(variables, 'CPLX_D_EUR', start_date, end_date)
      return [eco, eur].filter((e) => e != undefined) as DeviceValues[]
    case 'HUUMBOX':
      const ecod = await get_month_data(variables, 'ECOD', start_date, end_date)
      const eury = await get_month_data(variables, 'EURD', start_date, end_date)
      return [ecod, eury].filter((e) => e != undefined) as DeviceValues[]
    case 'JEEDOM':
      const vni = await get_month_data(variables, 'CPLX_D_PERF_VNI', start_date, end_date)
      const nad = await get_month_data(variables, 'CPLX_H_DH', start_date, end_date)
      return [vni, nad].filter((e) => e != undefined) as DeviceValues[]
    case 'SOLAIRE':
      const nrjeuroj = await get_month_data(variables, 'NRJEUROJ', start_date, end_date)
      const nrjp = await get_month_data(variables, 'NRJP', start_date, end_date)
      return [nrjeuroj, nrjp].filter((e) => e != undefined) as DeviceValues[]
    default:
      return []
  }
}

/**
 * Device values retrival for device cards
 * will not include all variables and request
 * the current day for most cases
 */
export async function get_device_values(
  device: components['schemas']['Device'],
  variables: components['schemas']['Variable'][]
): Promise<DeviceValue[]> {
  const store = useHypervisionStore()
  const month = store.getMonth
  const day = store.getDay

  switch (device.type) {
    case 'KIPO':
      const eco = await execute_calculation(
        variables,
        'CPLX_D_EV_ECO',
        month.first,
        month.last,
        sum
      )
      if (eco[0]) {
        eco[0].metric = 'm³/mois'
      }
      const eur = await execute_calculation(variables, 'CPLX_D_EUR', month.first, month.last, sum)
      if (eur[0]) {
        eur[0].metric = '€/mois'
      }

      return eco.concat(eur)
    case 'HUUMBOX':
      const ecod = await execute_calculation(variables, 'ECOD', month.first, month.last, sum)
      ecod[0].metric = 'm³/mois'
      const eury = await execute_calculation(variables, 'EURD', month.first, month.last, sum)
      eury[0].metric = '€/mois'

      return ecod.concat(eury)
    case 'JEEDOM':
      // prettier-ignore
      const perf_vni = await execute_calculation(variables,'CPLX_D_PERF_VNI',day.first,day.last,sum)
      if (perf_vni[0]) {
        perf_vni[0].metric = 'h'
      }

      const vent_nad = await execute_calculation(variables, 'CPLX_H_DH', day.first, day.last, avg)
      if (vent_nad[0]) {
        vent_nad[0].metric = 'DH'
      }

      const dha = await execute_calculation(variables, 'CPLX_H_DHA', day.first, day.last, avg)
      if (dha[0]) {
        dha[0].metric = 'DH'
      }

      return perf_vni.concat(vent_nad).concat(dha)
    case 'SOLAIRE':
      const nrjp = await execute_calculation(variables, 'NRJP', month.first, month.last, sum)
      nrjp[0].metric = 'kWh/mois'
      const nrjeuroj = await execute_calculation(
        variables,
        'NRJEUROJ',
        month.first,
        month.last,
        sum
      )
      nrjeuroj[0].metric = '€/mois'

      return nrjp.concat(nrjeuroj)
    default:
      return []
  }
}

export async function get_device_year_values(
  device: components['schemas']['Device'],
  variables: components['schemas']['Variable'][]
) {
  const store = useHypervisionStore()
  const day = store.getDay

  switch (device.type) {
    case 'KIPO':
      // prettier-ignore
      const eco = await execute_calculation(variables, 'CPLX_Y_EV_ECO', day.first, day.last, avg)
      if (eco[0]) {
        eco[0].metric = 'm³/an'
      }

      const eur = await execute_calculation(variables, 'CPLX_Y_EUR', day.first, day.last, avg)
      if (eur[0]) {
        eur[0].metric = '€/an'
      }

      return eco.concat(eur)
    case 'SOLAIRE':
      // prettier-ignore
      const nrjeco = await execute_calculation(variables, 'CPLX_Y_NRJP', day.first, day.last, avg)
      if (nrjeco[0]) {
        nrjeco[0].metric = 'kWh/an'
      }
      // prettier-ignore
      const nrjeuroj = await execute_calculation(variables, 'CPLX_Y_EURO', day.first, day.last, avg)
      if (nrjeuroj[0]) {
        nrjeuroj[0].metric = '€/an'
      }

      return nrjeco.concat(nrjeuroj)
    case 'HUUMBOX':
      const eura = await execute_calculation(variables, 'CPLX_Y_EURA', day.first, day.last, avg)
      eura[0].metric = '€/an'
      const ecoy = await execute_calculation(variables, 'ECOY', day.first, day.last, avg)
      ecoy[0].metric = 'm³/an'
      return eura.concat(ecoy)
    default:
      return []
  }
}

const { round } = Math

type ExtractionSet = {
  codes: string[]
  metric: string
  code: string
  title: string
}

function extract_device_year_values(
  devices: Map<string, ApplicationDevice>,
  extraction_set: ExtractionSet[],
  device_type?: string
): DeviceValue[] {
  const output: DeviceValue[] = extraction_set.map((set) => ({
    code: set.code,
    metric: set.metric,
    title: set.title,
    value: 0
  }))

  devices.forEach((device) => {
    for (const value of device.year) {
      for (const index in extraction_set) {
        const set = extraction_set[index]

        if (
          set.codes.find((e) => e === value.code) &&
          (device_type === undefined || device_type === device.type)
        ) {
          if (set.code === 'EAU' && Number.isNaN(value.value)) {
            console.log(device)
          }
          output[index].value += value.value
        }
      }
    }
  })

  return output
}

export function calculate_total(devices: Map<string, ApplicationDevice>, device_type?: string) {
  console.log(device_type)
  switch (device_type) {
    case 'solar':
      return extract_device_year_values(
        devices,
        [
          { title: 'Euros économisés', metric: '€', code: 'EUR', codes: ['CPLX_Y_EURO'] },
          {
            title: 'Energie économisés',
            metric: 'kWh',
            code: 'NRJECO',
            codes: ['CPLX_Y_NRJP']
          }
        ],
        'SOLAIRE'
      )
    case 'aquasmart':
      return extract_device_year_values(
        devices,
        [
          { title: 'Euros économisés', metric: '€', code: 'EUR', codes: ['CPLX_Y_EURA'] },
          { title: 'Eau économisés', metric: 'm³', code: 'EAU', codes: ['ECOY'] }
        ],
        'HUUMBOX'
      )
    case 'kipo':
      return extract_device_year_values(
        devices,
        [
          { title: 'Euros économisés', metric: '€', code: 'EUR', codes: ['CPLX_Y_EUR'] },
          { title: 'Eau économisés', metric: 'm³', code: 'EAU', codes: ['CPLX_Y_EV_ECO'] }
        ],
        'KIPO'
      )
    default:
      return extract_device_year_values(devices, [
        {
          title: 'Euros économisés',
          metric: '€',
          code: 'ECO',
          codes: ['CPLX_Y_EURO', 'CPLX_Y_EURA', 'CPLX_Y_EUR']
        },
        {
          title: 'Energie économisés',
          metric: 'kWh',
          code: 'NRJ',
          codes: ['CPLX_Y_NRJP']
        },
        {
          title: 'Eau économisés total',
          metric: 'm³',
          code: 'EAU',
          codes: ['ECOY', 'CPLX_Y_EV_ECO']
        }
      ])
  }
}

/**
 * Converts an HSL color value to RGB. Conversion formula
 * adapted from https://en.wikipedia.org/wiki/HSL_color_space.
 * Assumes h, s, and l are contained in the set [0, 1] and
 * returns r, g, and b in the set [0, 255].
 */
export function hslToRgb(h: number, s: number, l: number) {
  let r, g, b

  if (s === 0) {
    r = g = b = l // achromatic
  } else {
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s
    const p = 2 * l - q
    r = hueToRgb(p, q, h + 1 / 3)
    g = hueToRgb(p, q, h)
    b = hueToRgb(p, q, h - 1 / 3)
  }

  return [round(r * 255), round(g * 255), round(b * 255)]
}

function hueToRgb(p: number, q: number, t: number) {
  if (t < 0) t += 1
  if (t > 1) t -= 1
  if (t < 1 / 6) return p + (q - p) * 6 * t
  if (t < 1 / 2) return q
  if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6
  return p
}

export function d2(n: number) {
  return Math.round(n * 100) / 100
}

export const USED_VARIABLES = [
  'CPLX_D_EV_ECO',
  'CPLX_D_EUR',
  'ECOD',
  'EURD',
  'CPLX_D_PERF_VNI',
  'CPLX_H_DH',
  'CPLX_H_DHA',
  'NRJP',
  'NRJEUROJ'
]

function extract_month(values: Map<string, DeviceValues[]>, codes: string[]): number {
  let sum = 0

  for (const device of values) {
    for (const value of device[1]) {
      if (codes.includes(value.code)) {
        for (const v of value.values) {
          sum += v.value
        }
      }
    }
  }

  return sum
}

export function calculate_total_month(
  map: Map<string, DeviceValues[]>,
  device_type?: string
): DeviceValue[] {
  switch (device_type) {
    case 'solar':

    case 'aquasmart':

    case 'kipo':

    default:
      return [
        {
          title: 'Euros économisés',
          metric: '€',
          code: 'ECO',
          value: extract_month(map, ['EURD', 'CPLX_D_EUR', 'NRJEUROJ'])
        },
        {
          title: 'Energie économisés',
          metric: 'kWh',
          code: 'NRJ',
          value: extract_month(map, ['NRJP'])
        },
        {
          title: 'Eau économisés total',
          metric: 'm³',
          code: 'EAU',
          value: extract_month(map, ['CPLX_D_EV_ECO', 'ECOD'])
        }
      ]
  }
}
