<template>
  <div>
    <p
      class="uptitle"
      style="margin-bottom: 5px"
      v-if="store.forecasts.get(node.organizationUnitId!)"
    >
      METEO
    </p>
    <div class="common-weather-table">
      <div class="day" v-for="day in store.forecasts.get(node.organizationUnitId!)" :key="day.date">
        <img style="width: 30px" :src="day.icon" />
        <h5>{{ days[new Date(day.date).getDay()] }}.</h5>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ApplicationTreeNode } from '@/service/service_unit'
import { useWeatherStore } from '@/stores/store_weather'
import { onMounted, ref } from 'vue'

const props = defineProps<{
  node: ApplicationTreeNode
}>()

const days = ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam']
const store = useWeatherStore()

onMounted(() => {
  store.fetchForecast(props.node)
})
</script>

<style scoped lang="scss">
@import '@/assets/mixins.scss';
.common-weather-table {
  @include hlayout(4.5px);
  padding: 10px;
  background-color: var(--vc-gray1);
  height: fit-content;

  .day {
    padding: 10px;
    background-color: var(--vc-gray2);
    @include vlayout(5px);
    align-items: center;

    h5 {
      text-transform: uppercase;
    }
  }

  .week {
    @include hlayout(5px);
  }
}

.tablet,
.mobile {
  .common-weather-table .week {
    flex-wrap: wrap;
  }
}
</style>
