<template>
  <LayoutContainer class="view-authentication">
    <h1>Bienvenue sur l'outil SOPREMA Hypervision</h1>
    <form class="authentication-form" action="#" @submit.prevent="onAuthenticate">
      <InputCommon v-model="input_tenant" label="Tenant" />
      <InputCommon v-model="input_login" label="Login" />
      <InputCommon type="password" v-model="input_password" label="Mot de passe" />
      <input class="submit" type="submit" />
    </form>
  </LayoutContainer>
</template>

<script setup lang="ts">
import InputCommon from '@/components/common/input/InputCommon.vue'
import LayoutContainer from '@/components/layout/LayoutContainer.vue'
import { useHypervisionStore } from '@/stores/store_hypervision'
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'

const store = useHypervisionStore()
const input_tenant = ref('')
const input_login = ref('')
const input_password = ref('')
const route = useRoute()

function onAuthenticate() {
  store.authenticate(
    input_tenant.value,
    input_login.value,
    input_password.value,
    route.redirectedFrom?.fullPath
  )
}
</script>

<style scoped>
.authentication-form {
  background-color: var(--vc-gray1);
  padding: var(--spacer);
  max-width: 500px;
}

.authentication-form > .submit {
  padding: 10px;
  margin-top: 20px;
}

.view-authentication {
  display: flex;
  flex-direction: column;
  gap: var(--spacer);
  align-items: center;
}
</style>
