<template>
  <div class="composite-site-parameter-input">
    <InputCommon
      class="composite-site-parameter-input-input"
      v-bind:model-value="modelValue"
      v-on:update:model-value="(e) => emits('update:modelValue', e)"
      :label="label"
      gray
    />
    <span v-if="unit">{{ unit }}</span>
  </div>
</template>

<script setup lang="ts">
import InputCommon from '../common/input/InputCommon.vue'

defineProps<{
  label: string
  modelValue: string
  unit?: string
}>()

const emits = defineEmits<{
  (e: 'update:modelValue', value: string): void
}>()
</script>

<style scoped lang="scss">
.composite-site-parameter-input {
  position: relative;

  &-input {
    text-transform: capitalize;
  }
}

.composite-site-parameter-input > span {
  position: absolute;
  bottom: 24.8px;
  right: 20px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: 2px;
  text-transform: uppercase;
}
</style>
